import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

class Homepage extends React.Component {
    render() {
        const siteTitle = "NeuroFit";

        return (
            <Layout>
                <Helmet title={siteTitle} />
                <section id="header">
                    <div className="inner">
                        <span className="icon major fas fa-brain"></span>
                        <h1>
                          NeuroFit
                        </h1>
                        <p>
                          Introducing our latest product to help your child reach their neurological potential, <a href="/jr">Neurofit Jr.</a> 
                        </p>
                        <ul className="actions">
                          <li><a href="/jr" className="button scrolly">Discover</a></li>
                        </ul>
                    </div>
                </section>

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Induce broadspectrum cognitive enhancements using our proprietary headset</h2>
                            </header>
                            <p>The brain can be strengthened like any other muscle in our body. By taking advantage of its neuroplastic properties, the brain's neurons can be turbocharged through transcranial electrical stimulation (tES). Our proprietary headset delivers a safe level of transcranial direct current stimulation (tDCS) — allowing you to reach a state of flow faster and reach your superhuman potential.</p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={pic01} alt="" /></span>
                        </div>
                    </div>
                </section>

                <section id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <ul className="major-icons">
                                <li><span className="icon style1 major fas fa-bolt"></span></li>
                                <li><span className="icon style2 major fas fa-tachometer-alt"></span></li>
                                <li><span className="icon style3 major fas fa-user-friends"></span></li>
                                <li><span className="icon style4 major fas fa-tv"></span></li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>Join a community of NeuroOptimizers</h2>
                            </header>
                            <p>
                              Other consumer tDCS devices and DIY neurohacking projects promote solo usage. NeuroFit is a community first — with the shared goal of neuro perfection. The headset is calibrated to achieve the same cognitive enhancements and goals as other NeuroFit users. These targets include:
                            </p>

                            <ul>
                              <li>Unwavering confidence</li>
                              <li>Fearless</li>
                              <li>Lack of public shame</li>
                              <li>Loyalty</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="three" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>What's included with a NeuroFit membership?</h2>
                            </header>
                        </div>

                        <div className="col-4">
                            <span className="image fit"><img src={pic02} alt="" /></span>
                            <h3>The headset</h3>
                            <p>Our proprietary and patented design headset ensures your brain gets the electrical stimulation it needs.</p>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><img src={pic03} alt="" /></span>
                            <h3>A smart docking base</h3>
                            <p>The base charges and regulates the headset. It also acts as the primary method of updating your headset and headspace.</p>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><img src={pic04} alt="" /></span>
                            <h3>Custom saline solution</h3>
                            <p>The NeuroFit lab developed a saline solution that maximizes conductivity and minimizes tissue burning.</p>
                        </div>

                    </div>
                </section>

                <section id="four" className="main style2 special">
                    <div className="container">
                        <header className="major">
                            <h2>Ready to enable your brain's full neurological potential?</h2>
                        </header>
                        <ul className="actions uniform">
                            <li><a href="#" className="button special">Buy</a></li>
                            <li><a href="/midterm" className="button">Learn More</a></li>
                        </ul>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;
